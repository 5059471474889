import { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { SidebarCommunitiesWithLoader } from './SidebarCommunities';

const SidebarUserPanel = dynamic(
  () => import('./SidebarUserPanel').then((module) => module.SidebarUserPanel),
  {
    ssr: false,
  }
);
const SidebarControlPanel = dynamic(
  () =>
    import('./SidebarControlPanel').then(
      (module) => module.SidebarControlPanel
    ),
  {
    ssr: false,
  }
);

import { SquareIconButtonBase } from '@foundationPathAlias/components';
import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { classNames, useDetectScrollEnd } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { useGetUserCommunitiesPaginated } from '@mainApp/src/stores/hooks';
import { SidebarGuestCommunity } from './SidebarGuestCommunity';

// default height of control panel. It's slightly bigger than the allowed maximum height for it and it might be smaller after initialization
const CONTROL_PANEL_HEIGHT = 200;

type Props = {
  simplifiedMode?: boolean;
};

export function _SidebarCommunitiesColumn(props: Props) {
  const { simplifiedMode } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollEnd } = useDetectScrollEnd(0.1, scrollRef);
  const [controlPanelHeight, setControlPanelHeight] =
    useState(CONTROL_PANEL_HEIGHT);

  const {
    dimensionsStore: { isMobile },
    communityStore,
    authStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.communityStore,
    IOC_TOKENS.authStore,
  ]);

  const [startCommunitiesFetch, setStartCommunitiesFetch] = useState(false);

  useEffect(() => {
    if (scrollEnd) {
      const pageInfo = communityStore.userCommunities.meta.pageInfo;
      const hasPreviousPage = pageInfo?.hasPreviousPage;
      if (!hasPreviousPage) return;

      const nextPageVars = {
        ...communityStore.userCommunitiesPaginationVars,
        before: pageInfo.startCursor,
      };

      communityStore.setUserCommunitiesPaginationVars(nextPageVars);
    }
  }, [scrollEnd]);

  useEffect(() => {
    if (authStore.logged) {
      setStartCommunitiesFetch(true);
    }
  }, [authStore.logged]);

  const variables = communityStore.userCommunitiesPaginationVars;

  useGetUserCommunitiesPaginated(variables, startCommunitiesFetch);

  const userCommunities =
    communityStore.userCommunities.data?.valuesArray.slice().reverse() || [];

  const isIntiialLoading =
    communityStore.userCommunities.loading && userCommunities.length === 0;

  const isNonInitialLoading =
    communityStore.userCommunities.loading && userCommunities.length > 0;

  return (
    <div
      className={classNames('relative max-h-screen w-[68px]', {
        'w-auto': simplifiedMode && isMobile,
      })}
    >
      <div
        className={classNames(
          'fixed bottom-0 left-0 top-0 flex h-full w-[68px] flex-col bg-surface-mapping-secondary-base  pb-[16px] pt-[14px] md:bg-surface-mapping-tertiary-base',
          simplifiedMode && isMobile && 'relative w-auto'
        )}
      >
        <div
          className="relative flex w-[68px] flex-1"
          style={{
            maxHeight: `calc(99% - ${controlPanelHeight}px)`,
          }}
        >
          <div
            ref={scrollRef}
            className="hide-scrollbar relative flex max-h-full flex-1 flex-col items-center space-y-[16px] overflow-auto px-[14px] pb-[16px]"
          >
            {!simplifiedMode &&
              communityStore.activeCommunity.data?.isUserVisitorOrNonMember && (
                <SidebarGuestCommunity
                  activeCommunity={communityStore.activeCommunity.data}
                  isLoading={communityStore.activeCommunity.loading}
                />
              )}

            <SidebarCommunitiesWithLoader
              userCommunities={userCommunities}
              // show skeleton loader only for the initial loading. For pagination there will be other loader
              isLoading={isIntiialLoading}
              activeCommunityId={
                communityStore.activeCommunity.data?.serverData.id
              }
              logged={authStore.logged}
              activeCommunity={communityStore.activeCommunity.data}
            />

            {isNonInitialLoading && (
              <div className="px-[14px]">
                <SquareIconButtonBase
                  className="mb-[14px] h-[44px] w-[44px] shrink-0 rounded-[5px] bg-baseTheme-background-product dark:bg-surface-onBase-tertiary"
                  onClick={() => {}}
                >
                  <SmallLoader />
                </SquareIconButtonBase>
              </div>
            )}
          </div>
        </div>

        <div
          ref={(el) => {
            setControlPanelHeight(el?.clientHeight || CONTROL_PANEL_HEIGHT);
          }}
          className="mx-[12px] flex max-h-[194px] flex-col pt-[16px]"
        >
          <SidebarControlPanel simplifiedMode={Boolean(simplifiedMode)} />
          <SidebarUserPanel simplifiedMode={Boolean(simplifiedMode)} />
        </div>
      </div>
    </div>
  );
}

export const SidebarCommunitiesColumn = observer(_SidebarCommunitiesColumn);
