import {
  PlusBtnTooltip,
  ThreeDotsVerticalBtnTooltip,
} from '@foundationPathAlias/components/buttons/square';
import { classNames } from '@foundationPathAlias/utilities';

export const BUTTONS_SIZE_CN = 'h-[24px] w-[24px]';

export type Props = {
  cn?: string;
  noDotsBtn?: boolean;
  isDisableToolTipPlusBtn?: boolean;
  isDisableToolTipDotsBtn?: boolean;
  onPlusBtnClick: (e: React.SyntheticEvent) => void;
  onDotsBtnClick: (e: React.SyntheticEvent) => void;
  plusBtnTooltipContent: string;
  dotsBtnTooltipContent: string;
};

export function RightActions(props: Props) {
  const {
    cn,
    noDotsBtn,
    isDisableToolTipPlusBtn,
    isDisableToolTipDotsBtn,
    onPlusBtnClick,
    onDotsBtnClick,
    plusBtnTooltipContent,
    dotsBtnTooltipContent,
  } = props;

  return (
    <div className={classNames('mr-[4px] flex items-center', cn)}>
      <PlusBtnTooltip
        className={BUTTONS_SIZE_CN}
        TolltipProps={{
          triggerWrapperCn: BUTTONS_SIZE_CN,
          disabled: isDisableToolTipPlusBtn,
        }}
        onClick={onPlusBtnClick}
        darkMode={false}
        tooltipContent={plusBtnTooltipContent}
      />
      {!noDotsBtn && (
        <ThreeDotsVerticalBtnTooltip
          TolltipProps={{
            disabled: isDisableToolTipDotsBtn,
          }}
          className={BUTTONS_SIZE_CN}
          onClick={onDotsBtnClick}
          darkMode={false}
          tooltipContent={dotsBtnTooltipContent}
        />
      )}
    </div>
  );
}
