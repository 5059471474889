import { classNames, useLongPress } from '@foundationPathAlias/utilities';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';

import { RightActions } from './RightActions';

export type SidebarChannelHeadingPropsType = {
  cn?: string;
  /** for the heading wrapper only */
  headingCn?: string;
  plusBtnTooltipContent?: string;
  dotsBtnTooltipContent?: string;
  children?: string | React.ReactNode;
  noRightActions?: boolean;
  noDotsBtn?: boolean;
  noLeftIcon?: boolean;
  isMobile?: boolean;
  onHeadingClick?: () => void;
  onPlusClick?: (e: React.SyntheticEvent) => void;
  onDotsClick?: (e: React.SyntheticEvent) => void;
  onContextMenu?: (e: React.SyntheticEvent) => void;
  onLongPress?: (e: React.SyntheticEvent) => void;
  getHeadingRef?: (el: HTMLDivElement) => void;
  label: string;
};

export function _SidebarChannelHeading(props: SidebarChannelHeadingPropsType) {
  const {
    children,
    noLeftIcon,
    cn,
    headingCn,
    plusBtnTooltipContent,
    dotsBtnTooltipContent,
    noRightActions,
    noDotsBtn,
    label,
    isMobile,
    onHeadingClick,
    onContextMenu,
    onLongPress,
    onPlusClick,
    onDotsClick,
    getHeadingRef,
  } = props;

  const [open, setOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const hoverElRef = useRef<HTMLElement | null>(null);

  const longPressHandlers = useLongPress(onLongPress as any);
  const hideDotsBtn = !isHovered && !noDotsBtn && isMobile;

  return (
    <div className={classNames('relative', cn)}>
      <div className={classNames('relative pr-[4px]', headingCn)}>
        <div
          className="animation-classic flex text-buttonsAndIcons-textColor-onSecondary-default hover:text-buttonsAndIcons-textColor-onSecondary-hover"
          onContextMenu={onContextMenu}
          onTouchStart={longPressHandlers.onTouchStart as any}
          onTouchEnd={longPressHandlers.onTouchEnd as any}
          onMouseOver={() => {
            if (isMobile) return;
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          ref={(el) => {
            hoverElRef.current = el;
            getHeadingRef?.(el as HTMLDivElement);
          }}
        >
          <button
            className={classNames(
              'relative flex w-full flex-1 cursor-pointer items-center'
            )}
            onClick={() => {
              setOpen(!open);
              onHeadingClick?.();
            }}
          >
            {!noLeftIcon && (
              <div className="ml-[4px] mr-[2px] mt-[2px] flex h-[16px] items-center justify-center">
                <ChevronRightIcon
                  className={classNames(
                    'h-[16px] transition-all duration-500 ease-in-out',
                    open && 'rotate-90'
                  )}
                />
              </div>
            )}
            <div
              className={classNames(
                'relative h-full flex-1',
                noLeftIcon && 'ml-[18px]'
              )}
            >
              <p className="absolute left-0 w-full overflow-hidden text-ellipsis text-left text-body16SB">
                {label}
              </p>
            </div>
          </button>

          {!noRightActions && (
            <RightActions
              onPlusBtnClick={onPlusClick as () => void}
              onDotsBtnClick={onDotsClick as () => void}
              isDisableToolTipPlusBtn={isMobile}
              noDotsBtn={hideDotsBtn}
              plusBtnTooltipContent={plusBtnTooltipContent as string}
              dotsBtnTooltipContent={dotsBtnTooltipContent as string}
            />
          )}
        </div>
      </div>

      {children && (
        <div
          className={classNames(
            'w-full overflow-hidden transition-all duration-500 ease-in-out',
            open ? 'max-h-[1000px]' : 'max-h-0'
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export const SidebarChannelHeading = observer(_SidebarChannelHeading);
