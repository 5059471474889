import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommunityTitle } from './CommunityTitle';

import { SidebarSeparator } from '@mainApp/src/components/sidebar/SidebarSeparator';
import { paths } from '@mainApp/src/config';
import { useJoinCommunityAction } from '@mainApp/src/hooks';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import {
  RedirectionURLActionsEnum,
  RedirectionURLQueriesEnum,
} from '@mainApp/src/services';
import { removeQueryParamsFromRouter } from '@mainApp/src/utils';
import { ChannelItemWithMenu } from '../ChannelItemWithMenu';
import { ChannelGroupHeadingWithMenu } from './ChannelGroupHeadingWithMenu';

import {
  ChannelGroupContextMenuProvider,
  GroupContextMenu,
} from './channel-group-context-menu';

import { DndReorderContainers } from '@10x/foundation/src/components/dnd/dnd-reorder-containers';
import { Separator } from '@foundationPathAlias/main';
import { classNames } from '@mainApp/../foundation/src/utilities';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';
import { ChannelGroupListLoader } from '../loaders';

const JoinCommunityButton = dynamic(() => import('./JoinCommunityButton'), {
  ssr: false,
});
const JoinCommunityDescription = dynamic(
  () => import('./JoinCommunityDescription'),
  {
    ssr: false,
  }
);

export function _SidebarChannelsColumn() {
  const {
    channelStore,
    communityStore,
    authStore,
    createChannelOrGroupStore,
    dimensionsStore: { isMobile },
    systemStore,
  } = useMultipleInjection([
    IOC_TOKENS.channelStore,
    IOC_TOKENS.authStore,
    IOC_TOKENS.communityStore,
    IOC_TOKENS.createChannelOrGroupStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.systemStore,
  ]);

  const [reorderError, setReorderError] = useState<string | null>(null);

  const { t } = useTranslation(['common', 'channel']);
  const activeSlugRef = useRef<string | null>(null);

  const router = useRouter();
  const { push, query } = router;
  const queryChannelSlug = query.channel_slug;
  const communitySlug = query?.community_slug;

  const joinCommunityAction = useJoinCommunityAction(false);

  useEffect(() => {
    const communityId = communityStore.activeCommunity.data?.serverData.id;

    const slug = query?.community_slug;

    if (slug) {
      fetchChannelGroups(slug as string);
      // TODO: Investigate how to implement urql optimistic update with variable policy for network-only or cache-and-network
      // proceeds optimistic responses and initial network fetch. It unsubscribes in the Channel Component on Unmount. It's because the Sidebar layout is active for other pages as well (like Explore, but we don't need there any active commynity)
      communityStore.setSubscriptionCommunityBySlugOnce(slug as string);
      // for the use case when the user changes on the same page by email code auth status from non-member to member and need to re-fetch from BE only the actual data.
      if (communityId) {
        communityStore.getAndSetActiveCommunity(communityId);
      }
    }

    activeSlugRef.current = slug as string;

    return () => {
      channelStore.resetChannelGroups();
    };
  }, [query, authStore.logged]);

  useEffect(() => {
    if (!router.isReady) return;

    const communityId = communityStore.activeCommunity.data?.serverData.id;
    const scheduledActionsQuery = RedirectionURLQueriesEnum.SCHEDULED_ACTIONS;
    const scheduledAction = query[scheduledActionsQuery];
    if (scheduledAction && communityId) {
      // TODO: for simplicitynow it's only join but when there will be multiple
      // actions need to create a dedicated service/handling for the
      // scheduled actions in queue
      const isNonMemberOrVisitor =
        communityStore.activeCommunity?.data?.isUserVisitorOrNonMember;

      // TODO: not sure if it's ok to keep it in the sidebar. Should think about this location
      if (
        scheduledAction === RedirectionURLActionsEnum.JOIN &&
        isNonMemberOrVisitor
      ) {
        communityStore
          .joinCommunity(communityId, query?.community_slug as string)
          .finally(() => {
            delete router.query[scheduledActionsQuery];
          });
      }

      // should remove state from URL
      removeQueryParamsFromRouter(router, [
        RedirectionURLQueriesEnum.SCHEDULED_ACTIONS,
      ]);
      // router.replace({ pathname, query }, undefined, { shallow: true });
    }
  }, [communityStore.activeCommunity?.data?.serverData?.id, router.isReady]);

  async function fetchChannelGroups(communitySlug: string) {
    const community = await communityStore.getCommunityBySlugName(
      communitySlug
    );

    if (!community) {
      return;
    }

    const communityId = community.serverData.id;
    await channelStore.getChannelGroups(communityId);
  }

  const groups = channelStore.channelGroups.data || [];
  let dndGroupsWithChannels: Record<string, string[]> = {};
  const dndGroupsAndChannelsRegistry: Record<
    string,
    ChannelGroupModel | ChannelModel
  > = {};

  const dragDisabledContainerIds: string[] = [];

  if (groups.length) {
    dndGroupsWithChannels = groups.reduce((acc, channelGroup) => {
      dndGroupsAndChannelsRegistry[channelGroup.id] = channelGroup;

      const isUngrouped = channelStore.checkIfGroupIsUngrouped(channelGroup);
      if (isUngrouped) {
        // it shouldn't be draggable at all
        dragDisabledContainerIds.push(channelGroup.id);
        // shouldn't show empty ungrouped channels group
        if (channelGroup.channels.length === 0) {
          return acc;
        }
      }

      acc[channelGroup.id] = channelGroup.channels.map((channelModel) => {
        const id = channelModel.serverData.id;
        dndGroupsAndChannelsRegistry[id] = channelModel;
        return id;
      });
      return acc;
    }, {} as Record<string, string[]>);
  }

  const isChannelsData = Boolean(channelStore.channelNormalGroups.data.length);
  const isInitialLoadingChannels =
    !isChannelsData && channelStore.channelNormalGroups.loading;

  const isUserAdminOrOwner =
    communityStore.activeCommunity.data?.isUserAdminOrOwner;

  const channelSettingsLabel = t('channel:channelSettings.label');

  return (
    <aside className="flex h-full w-[248px] flex-col bg-surface-mapping-base-secondary pb-[19px] md:bg-surface-onBase-secondary">
      <div>
        <div className="flex h-[44px] items-center justify-between pl-[24px] pr-[16px] pt-[4px] md:h-[53px] ">
          <CommunityTitle
            title={communityStore.activeCommunity.data?.serverData.name || ''}
            isLoading={communityStore.activeCommunity.loading}
          />
        </div>
        <SidebarSeparator />

        <div
          className={classNames(
            'flex min-h-[64px] flex-col px-[24px]',
            communityStore.activeCommunity.data?.isUserVisitorOrNonMember &&
              'items-start pb-[15px]'
          )}
        >
          <JoinCommunityDescription
            show={Boolean(
              communityStore.activeCommunity.data?.isUserVisitorOrNonMember
            )}
            description={
              communityStore.activeCommunity.data?.serverData.description ||
              `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt.`
            }
          />
          <div className="themed-text-secondary flex flex-1 items-center text-body16SB">
            <span>
              {t('members', {
                count:
                  communityStore.activeCommunity.data?.serverData.members
                    .totalCount || 0,
              })}
            </span>
            <i className="mx-[8px] mt-[4px] block h-[6px] w-[6px] rounded-full bg-text-secondary" />
            <span>
              {communityStore.activeCommunity.data?.serverData.onlineMembers
                .totalCount || 0}{' '}
              {t('online').toLowerCase()}
            </span>
          </div>

          <JoinCommunityButton
            show={Boolean(
              communityStore.activeCommunity.data?.isUserVisitorOrNonMember
            )}
            onClick={joinCommunityAction}
          />
        </div>
        <SidebarSeparator />
      </div>

      <div className="sidebar-channels-wrapper scrollbar-track-rounded-full scrollbar-thumb-rounded-full flex-1 overflow-auto scrollbar-thin scrollbar-track-element-subtle scrollbar-thumb-color-4">
        <div className="flex flex-col px-[8px]">
          {isInitialLoadingChannels ? (
            <ChannelGroupListLoader />
          ) : (
            <ChannelGroupContextMenuProvider>
              {isChannelsData && (
                <DndReorderContainers<ChannelGroupModel, ChannelModel>
                  containersWithItems={dndGroupsWithChannels}
                  containersAndItemsRegistry={dndGroupsAndChannelsRegistry}
                  dragDisabledContainerIds={dragDisabledContainerIds}
                  findContainerById={(groupId: string) => {
                    const container = groups.find(
                      (group) => group.id === groupId
                    );

                    if (!container) {
                      throw new Error('Container not found');
                    }
                    return container;
                  }}
                  reorderError={Boolean(reorderError)}
                  isMobile={isMobile}
                  // only admins or owners can reorder
                  disabled={!isUserAdminOrOwner}
                  onDragStart={() => {
                    if (reorderError) {
                      setReorderError(null);
                    }
                  }}
                  onMoveItemToNewGroup={async (
                    channelModel: ChannelModel,
                    orderIndex: number,
                    newGroupId: string
                  ) => {
                    const { communityId, id } = channelModel.serverData;
                    const { error } = await channelStore.updateChannel(
                      communityId,
                      id,
                      {
                        channelGroupId: newGroupId,
                        order: orderIndex,
                      },
                      true
                    );

                    if (error) {
                      setReorderError(error);
                    }
                  }}
                  onContainersReorder={async (
                    groupIdsOrdered: string[],
                    activeModel: ChannelGroupModel
                  ) => {
                    const communityId = activeModel.communityId;

                    const error = await channelStore.updateChannelGroupsOrder(
                      communityId,
                      groupIdsOrdered
                    );

                    if (error) {
                      setReorderError(error);
                    }
                  }}
                  onItemsReorder={async (
                    channelIdsOrdered: string[],
                    parentChannelGroup: ChannelGroupModel
                  ) => {
                    const error = await channelStore.updateChannelsOrderInGroup(
                      parentChannelGroup.communityId,
                      parentChannelGroup.id,
                      channelIdsOrdered
                    );

                    if (error) {
                      setReorderError(error);
                    }
                  }}
                  getParentContainerId={(dndDraggableItem) => {
                    return dndDraggableItem?.data?.current?.model.serverData
                      .parentId;
                  }}
                  renderContainerDragOverlay={(dataModel) => {
                    return (
                      <ChannelGroupHeadingWithMenu
                        onPlusClick={() => null}
                        noLeftIcon={dataModel.channels.length === 0}
                        hideRightActions={channelStore.checkIfGroupIsUngrouped(
                          dataModel
                        )}
                        channelGroupModel={dataModel}
                      >
                        <div className="my-[12px] flex flex-col space-y-[12px]">
                          {dataModel.channels.map((channelModel) => {
                            const channelName = channelModel.serverData.name;
                            return (
                              <ChannelItemWithMenu
                                key={channelName}
                                isUserAdminOrOwner={Boolean(isUserAdminOrOwner)}
                                tooltipContent={channelSettingsLabel}
                                active={false}
                                newMessagesCount={null}
                                channelModel={channelModel}
                                onPointerUp={() => null}
                              />
                            );
                          })}
                        </div>
                      </ChannelGroupHeadingWithMenu>
                    );
                  }}
                  renderItemDragOverlay={(dataModel) => {
                    return (
                      <ChannelItemWithMenu
                        isUserAdminOrOwner={Boolean(isUserAdminOrOwner)}
                        tooltipContent={channelSettingsLabel}
                        active={false}
                        newMessagesCount={null}
                        channelModel={dataModel}
                        onPointerUp={() => null}
                      />
                    );
                  }}
                  ContainerContentComponent={({ children, dataModel }) => {
                    // if it's ungrouoped so should render only separator and it shouldn't be draggable. Only channels inside
                    if (dataModel.isUngrouped) {
                      return (
                        <div className="flex flex-1 flex-col">
                          <Separator />
                          {children}
                        </div>
                      );
                    }
                    return (
                      <ChannelGroupHeadingWithMenu
                        channelGroupModel={dataModel}
                        noLeftIcon={dataModel.channels.length === 0}
                        hideRightActions={channelStore.checkIfGroupIsUngrouped(
                          dataModel
                        )}
                        onPlusClick={(id) => {
                          createChannelOrGroupStore.setCurrentChannelGroupId(
                            id
                          );
                          createChannelOrGroupStore.show();
                        }}
                      >
                        {children}
                      </ChannelGroupHeadingWithMenu>
                    );
                  }}
                  ItemComponent={({ dataModel }) => {
                    const channelSlug = dataModel.serverData.slug;
                    return (
                      <ChannelItemWithMenu
                        isUserAdminOrOwner={Boolean(isUserAdminOrOwner)}
                        tooltipContent={channelSettingsLabel}
                        active={queryChannelSlug === channelSlug}
                        newMessagesCount={null}
                        channelModel={dataModel}
                        onPointerUp={() => {
                          push(
                            paths.getChannelPath(
                              communitySlug as string,
                              channelSlug as string
                            )
                          );
                          if (isMobile) {
                            systemStore.setIsSidebarOpened(false);
                          }
                        }}
                      />
                    );
                  }}
                />
              )}
              <GroupContextMenu />
            </ChannelGroupContextMenuProvider>
          )}
        </div>
      </div>
    </aside>
  );
}

export const SidebarChannelsColumn = observer(_SidebarChannelsColumn);
